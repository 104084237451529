
import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazy-load';
import './App.css';

import splashVideo from './assets/GarrettShannonSplashVideo.mp4';

import { ReactComponent as LogoSquareSVG } from './assets/logoSquareWhiteSVG.svg';
import { ReactComponent as ImdbLogo } from './assets/imdb.svg';
import { ReactComponent as InstagramLogo } from './assets/instagram.svg';
import { ReactComponent as LinkedinLogo } from './assets/linkedin.svg';
import { ReactComponent as VimeoLogo } from './assets/vimeo.svg';

/* HEADSHOT */
import Headshot from './assets/headshot.jpg'


/* ADD FEATURED VIDEOS HERE */
function FeaturedVideos (props) {
  return (
    <div id="videoImages">
      {/* Commercial Reel */}
      <FeaturedThumbnailText modal={props.modal} setModal={props.setModal}
        thumbnailVideo={require('./assets/thumbnails/CommercialReel-500x250-Animated.mp4')}
        thumbnailImage={require('./assets/thumbnails/CommercialReel.jpg')}
        titleText="COMMERCIAL REEL"
        videoLink="https://player.vimeo.com/video/408026587?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Austin FC */}
      <FeaturedThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailVideo={require('./assets/thumbnails/AustinFC-FirstandOnly-500x250-Animated.mp4')}
        thumbnailImage={require('./assets/thumbnails/AustinFC-FirstandOnly.jpg')}
        svgLogo={require('./assets/logos/AustinFC.svg')}
        titleText="AUSTIN FC - FIRST AND ONLY"
        videoLink="https://player.vimeo.com/video/342030897?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Narrative Reel */}
      <FeaturedThumbnailText modal={props.modal} setModal={props.setModal}
        thumbnailVideo={require('./assets/thumbnails/NarrativeReel-500x250-Animated.mp4')}
        thumbnailImage={require('./assets/thumbnails/NarrativeReel.jpg')}
        titleText="NARRATIVE REEL"
      />
      {/* Mississippi State */}
      <FeaturedThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailVideo={require('./assets/thumbnails/MississippiState-WeRingTrue-500x250-Animated.mp4')}
        thumbnailImage={require('./assets/thumbnails/MississippiState-WeRingTrue.jpg')}
        svgLogo={require('./assets/logos/MississippiState.svg')}
        titleText="MISSISSIPPI STATE - WE RING TRUE"
        videoLink="https://player.vimeo.com/video/403711327?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* The Chef Show */}
      <FeaturedThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailVideo={require('./assets/thumbnails/Netflix-TheChefShow-Animated.mp4')}
        thumbnailImage={require('./assets/thumbnails/Netflix-TheChefShow.jpg')}
        svgLogo={require('./assets/logos/Netflix-TheChefShow.svg')}
        titleText="NETFLIX - THE CHEF SHOW"
        videoLink="https://www.youtube-nocookie.com/embed/nz7F2K4-waw?&autoplay=1"
      />
      {/* Hugo Boss */}
      <FeaturedThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailVideo={require('./assets/thumbnails/HugoBoss-JosephSchooling-500x250-Animated.mp4')}
        thumbnailImage={require('./assets/thumbnails/HugoBoss-JosephSchooling.jpg')}
        svgLogo={require('./assets/logos/HugoBoss.svg')}
        titleText="HUGO BOSS - JOSEPH SCHOOLING"
        videoLink="https://player.vimeo.com/video/342037866?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Black Pumas Black Moon Rising */}
      <FeaturedThumbnailText modal={props.modal} setModal={props.setModal}
        thumbnailVideo={require('./assets/thumbnails/BlackPumas-BlackMoonRising-500x250-Animated.mp4')}
        thumbnailImage={require('./assets/thumbnails/BlackPumas-BlackMoonRising.jpg')}
        titleText="BLACK PUMAS - BLACK MOON RISING"
        videoLink="https://www.youtube-nocookie.com/embed/bynWXFY5U6U?&autoplay=1"
      />
      {/* Smile Doctors */}
      <FeaturedThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailVideo={require('./assets/thumbnails/SmileDr-SmileHappy-500x250-Animated.mp4')}
        thumbnailImage={require('./assets/thumbnails/SmileDr-SmileHappy.jpg')}
        svgLogo={require('./assets/logos/SmileDoctors.svg')}
        titleText="SMILE DOCTORS - SMILE HAPPY"
        videoLink="https://player.vimeo.com/video/342036692?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Cinch Jeans */}
      <FeaturedThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailVideo={require('./assets/thumbnails/Cinch-Chace-Animated.mp4')}
        thumbnailImage={require('./assets/thumbnails/Cinch-Chace.jpg')}
        svgLogo={require('./assets/logos/Cinch.svg')}
        titleText="CINCH JEANS - DREAMS"
        videoLink="https://player.vimeo.com/video/403788280?autoplay=1&title=0&byline=0&portrait=0"
      />
    </div>
  );
}

/* ADD Branded Doc HERE */
function BrandedDocs (props) {
  return (
    <div id="videoImages">
      {/* Marriott */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/Marriott-Karamo.jpg')}
        svgLogo={require('./assets/logos/Marriott.svg')}
        titleText="MARRIOTT - KARAMO"
        videoLink="https://player.vimeo.com/video/403709385?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Nike Sport */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/Nike-SportChangesEverything.jpg')}
        svgLogo={require('./assets/logos/Nike.svg')}
        titleText="NIKE - SPORT CHANGES EVERYTHING"
        videoLink="https://player.vimeo.com/video/392871797?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Apple Music */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/AppleMusic-DierksBentley.jpg')}
        svgLogo={require('./assets/logos/Apple-Music.svg')}
        titleText="APPLE MUSIC - DIERKS BENTLEY"
        videoLink="https://player.vimeo.com/video/261513305?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Facebook Heroes */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/Facebook-Heroes.jpg')}
        svgLogo={require('./assets/logos/Facebook.svg')}
        titleText="FACEBOOK - HEROES"
        videoLink="https://player.vimeo.com/video/129049358?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Delta Hotels */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/DeltaHotels-Momentum.jpg')}
        svgLogo={require('./assets/logos/DeltaHotels.svg')}
        titleText="DELTA HOTELS - MOMENTUM"
        videoLink="https://player.vimeo.com/video/342035848?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Foot Locker */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/FootLocker-DAngeloRussell.jpg')}
        svgLogo={require('./assets/logos/FootLocker.svg')}
        titleText="FOOT LOCKER - D'ANGELO RUSSELL"
        videoLink="https://player.vimeo.com/video/253670572?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Nulo Pet Food */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/NuloPetFood-JordanHicks.jpg')}
        svgLogo={require('./assets/logos/Nulo.svg')}
        titleText="NULO PET FOOD -  JORDAN HICKS"
        videoLink="https://player.vimeo.com/video/392871146?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Panda Express */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/PandaExpress-ChineseNewYear.jpg')}
        svgLogo={require('./assets/logos/PandaExpress.svg')}
        titleText="PANDA EXPRESS - CHINESE NEW YEAR"
        videoLink="https://player.vimeo.com/video/403709574?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Nike Potential */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/NikeN7-Potential.jpg')}
        svgLogo={require('./assets/logos/Nike.svg')}
        titleText="NIKE N7 - POTENTIAL"
        videoLink="https://player.vimeo.com/video/179100606?autoplay=1&title=0&byline=0&portrait=0"
      />
    </div>
  );
}

/* ADD COMMERCIAL VIDEOS HERE */
function Commercial (props) {
  return (
    <div id="videoImages">
      {/* Mississippi State */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/MississippiState-WeRingTrue.jpg')}
        svgLogo={require('./assets/logos/MississippiState.svg')}
        titleText="MISSISSIPPI STATE - WE RING TRUE"
        videoLink="https://player.vimeo.com/video/403711327?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Hugo Boss */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/HugoBoss-JosephSchooling.jpg')}
        svgLogo={require('./assets/logos/HugoBoss.svg')}
        titleText="HUGO BOSS - JOSEPH SCHOOLING"
        videoLink="https://player.vimeo.com/video/342037866?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Polycom */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/Polycom-OfficeGuy.jpg')}
        svgLogo={require('./assets/logos/Polycom.svg')}
        titleText="POLYCOM - OFFICE GUY"
        videoLink="https://player.vimeo.com/video/403788857?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Burger Lounge */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/BurgerLounge-Contained.jpg')}
        svgLogo={require('./assets/logos/BurgerLounge.svg')}
        titleText="BURGER LOUNGE - CONTAINED"
        videoLink="https://player.vimeo.com/video/171005201?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Smile Doctors */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/SmileDr-SmileHappy.jpg')}
        svgLogo={require('./assets/logos/SmileDoctors.svg')}
        titleText="SMILE DOCTORS - SMILE HAPPY"
        videoLink="https://player.vimeo.com/video/342036692?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Jack Links */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/JackLinks-Sasquatch.jpg')}
        svgLogo={require('./assets/logos/JackLinks.svg')}
        titleText="JACK LINKS - SASQUATCH"
        videoLink="https://player.vimeo.com/video/257131473?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Facebook Games */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/FacebookGames-Dino.jpg')}
        svgLogo={require('./assets/logos/Facebook.svg')}
        titleText="FACEBOOK GAMES - DINO HUNTER"
        videoLink="https://player.vimeo.com/video/129049784?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Rayovac */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/Rayovac-Radio.jpg')}
        svgLogo={require('./assets/logos/Rayovac.svg')}
        titleText="RAYOVAC - RADIO"
        videoLink="https://player.vimeo.com/video/171005199?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Nike NSW */}
      <ImageThumbnail modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/NikeN7-NSWToronto.jpg')}
        svgLogo={require('./assets/logos/Nike.svg')}
        titleText="NIKE - NSW TORONTO"
        videoLink="https://player.vimeo.com/video/179100605?autoplay=1&title=0&byline=0&portrait=0"
      />
    </div>
  );
}

/* ADD Music Video HERE */
function MusicVideos (props) {
  return (
    <div id="videoImages">
      {/* Black Pumas Black Moon Rising */}
      <ImageThumbnailText modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/BlackPumas-BlackMoonRising.jpg')}
        titleText="BLACK PUMAS - BLACK MOON RISING"
        videoLink="https://www.youtube-nocookie.com/embed/bynWXFY5U6U?&autoplay=1"
      />
      {/* Walk The Moon */}
      <ImageThumbnailText modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/WalkTheMoon-ShutUpandDance.jpg')}
        titleText="WALK THE MOON - SHUT UP AND DANCE"
        videoLink="https://www.youtube-nocookie.com/embed/6JCLY0Rlx6Q?&autoplay=1"
      />
      {/* Big Dipper */}
      <ImageThumbnailText modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/BigDipper-LaCroixBoi.jpg')}
        titleText="BIG DIPPER - LA CROIX BOI"
        videoLink="https://www.youtube-nocookie.com/embed/LB_YWGuYGn4?&autoplay=1"
      />
      {/* Castlecomer */}
      <ImageThumbnailText modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/Castlecomer-AlloftheNoise.jpg')}
        titleText="CASTLECOMER - ALL OF THE NOISE"
        videoLink="https://player.vimeo.com/video/403802253?autoplay=1&title=0&byline=0&portrait=0"
      />
      {/* Black Pumas Elearnor */}
      <ImageThumbnailText modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/BlackPumas-EleanorRigby.jpg')}
        titleText="BLACK PUMAS - ELEANOR RIGBY LIVE"
        videoLink="https://www.youtube-nocookie.com/embed/F6Pr46spIis?&autoplay=1"
      />
      {/* Pompeya */}
      <ImageThumbnailText modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/Pompeya-Pasadena.jpg')}
        titleText="POMPEYA - PASADENA"
        videoLink="https://www.youtube-nocookie.com/embed/NqrGWdXrAoU?&autoplay=1"
      />
      {/* Nana */}
      <ImageThumbnailText modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/Nana-RunningOutofTime.jpg')}
        titleText="NANA - RUNNING OUT OF TIME"
        videoLink="https://www.youtube-nocookie.com/embed/NEd1Dh8C13I?&autoplay=1"
      />
      {/* Miknna Electric */}
      <ImageThumbnailText modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/Miknna-Electric.jpg')}
        titleText="MIKNNA - ELECTRIC"
        videoLink="https://www.youtube-nocookie.com/embed/2w-yQ_Sp5WE?&autoplay=1"
      />
      {/* Miknna Euphoria */}
      <ImageThumbnailText modal={props.modal} setModal={props.setModal}
        thumbnailImage={require('./assets/thumbnails/Miknna-Euphoria.jpg')}
        titleText="MIKNNA - EUPHORIA"
        videoLink="https://www.youtube-nocookie.com/embed/VjA5YoKVc1w?&autoplay=1"
      />
    </div>
  );
}

function ImageThumbnailText (props) {
  return (
    <div className="videoCard">
      <div className="logoContainer centerVertically">
        <div className="centerHorizontally">
          <h3 className="thumbnailText">{props.titleText}</h3>
        </div>
      </div>
      <a onClick={() => props.setModal(props.videoLink)} className="videoLink">
        <div className="videoThumbnail">
          <LazyLoad offsetVertical={0}>
            <img className="videoThumbnail" src={props.thumbnailImage} alt={props.titleText} />
          </LazyLoad>
        </div>
      </a>
    </div>
  );
}

function ImageThumbnail (props) {
  return (
    <div className="videoCard">
      <div className="logoContainer centerVertically">
        <div className="centerHorizontally">
          <LazyLoad offsetVertical={0} style={{ width:'100%', height:'100%' }}>
            <img src={props.svgLogo} className="videoLogo" />
          </LazyLoad>
        </div>
      </div>
      <a onClick={() => props.setModal(props.videoLink)} className="videoLink">
        <div className="videoThumbnail">
          <LazyLoad offsetVertical={500}>
            <img className="videoThumbnail" src={props.thumbnailImage} alt={props.titleText} />
          </LazyLoad>
        </div>
      </a>
    </div>
  );
}

function FeaturedThumbnailText (props) {
  if (window.innerWidth < 931) {
    return (
      <div className="videoCard">
        <div className="logoContainer centerVertically">
          <div className="centerHorizontally">
            <h3 className="thumbnailText">{props.titleText}</h3>
          </div>
        </div>
        <a onClick={() => props.setModal(props.videoLink)} className="videoLink">
          <div className="videoThumbnail">
            <img className="videoThumbnail" src={props.thumbnailImage} alt={props.titleText} />
          </div>
        </a>
      </div>
    );
  }
  return (
    <div className="videoCard">
      <div className="logoContainer centerVertically">
        <div className="centerHorizontally">
          <h3 className="thumbnailText">{props.titleText}</h3>
        </div>
      </div>
      <a onClick={() => props.setModal(props.videoLink)} className="videoLink">
        <div className="videoThumbnail">
          <video
            playsInline preload="auto" loop className="videoThumbnail"
            poster={props.thumbnailVideo}
            onMouseOver={event => {
              if (window.innerWidth > 930) {
                event.target.play();
              }
              if (event.target.currentTime >1) {
                event.target.currentTime = 0
              }
            }}
            onMouseOut={event => {
              event.target.pause();
              if (event.target.currentTime > 1) {
                event.target.currentTime = 0
              }
            }}>
              <source src={props.thumbnailVideo} type="video/mp4" wdith="500px" height="250px" />
          </video>
        </div>
      </a>
    </div>
  );
}

function FeaturedThumbnail (props) {
  if (window.innerWidth < 931) {
    return (
      <div className="videoCard">
        <div className="logoContainer centerVertically">
          <div className="centerHorizontally">
            <LazyLoad offsetVertical={0}>
              <img src={props.svgLogo} className="videoLogo" />
            </LazyLoad>
          </div>
        </div>
        <a onClick={() => props.setModal(props.videoLink)} className="videoLink">
          <div className="videoThumbnail">
            <img className="videoThumbnail" src={props.thumbnailImage} alt={props.titleText} />
          </div>
        </a>
      </div>
    );
  }

  return (
    <div className="videoCard">
      <div className="logoContainer centerVertically">
        <div className="centerHorizontally">
          <LazyLoad offsetVertical={0}>
            <img src={props.svgLogo} className="videoLogo" />
          </LazyLoad>
        </div>
      </div>
      <a onClick={() => props.setModal(props.videoLink)} className="videoLink">
        <div className="videoThumbnail">
            <video
              playsInline preload="auto" loop className="videoThumbnail"
              poster={props.thumbnailVideo}
              onMouseOver={event => {
                if (window.innerWidth > 930) {
                  event.target.play();
                }
                if (event.target.currentTime >1) {
                  event.target.currentTime = 0
                }
              }}
              onMouseOut={event => {
                event.target.pause();
                if (event.target.currentTime > 1) {
                  event.target.currentTime = 0
                }
              }}>
                <source src={props.thumbnailVideo} type="video/mp4" wdith="500px" height="250px" />
            </video>
        </div>
      </a>
    </div>
  );
}

function LandingSection () {
  return (
    <div className="landingSection">
      <video playsInline autoPlay muted loop preload="auto">
        <source src={splashVideo} type="video/mp4" wdith="1920px" height="1080px" id="videoHorizontal"/>
      </video>
    </div>
  );
}

function VideoModal (props) {

  return (
    <a onClick={() => props.setModal("")} id="modalContainer" className={"centerVertically " + ((props.modal == "") ? "" : "active")}>
      <div className="centerHorizontally" style={{height: 'auto'}}>
        <div id="modalBox">
          <div style={{padding:'56.25% 0 0 0',position:'relative'}}>
            <iframe src={props.modal} style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </a>
  );
}

function App() {
  //Menu state
  const [menu, setMenu] = useState("");
  //Category to display
  const [category, setCategory] = useState("Featured");
  //Video modal state
  const [modal, setModal] = useState("");

  return (
    <div className="App">
      <VideoModal modal={modal} setModal={setModal}/>

      <LogoSquareSVG id="logo" className={menu} />

      <svg className={"ham hamRotate ham1 " + menu} id="menuIcon" viewBox="0 0 100 100" width="80" onClick={() => setMenu( (menu == "active") ? "" : "active" )}>
        <path
              className={"line top " + menu}
              d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
        <path
              className={"line middle " + menu}
              d="m 30,50 h 40" />
        <path
              className={"line bottom " + menu}
              d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
      </svg>


      <div className={"menu " + menu}>
        <div id="menuLinks">
          <a onClick={()=>{setCategory("Featured"); setMenu("")}} href="#videosSection">
            <h1 className="menuLink">Featured</h1>
          </a>
          <a className="menuATag" onClick={()=>{setCategory("Commercial"); setMenu("")}} href="#videosSection">
            <h1 className="menuLink">Commercial</h1>
          </a>
          <a className="menuATag" onClick={()=>{setCategory("Branded Doc"); setMenu("")}} href="#videosSection">
            <h1 className="menuLink">Branded Doc</h1>
          </a>
          <a className="menuATag" onClick={()=>{setCategory("Music Video"); setMenu("")}} href="#videosSection">
            <h1 className="menuLink">Music Video</h1>
          </a>
          <a className="menuATag" onClick={()=>setMenu("")} href="#aboutSection">
            <h1 className="menuLink">About</h1>
          </a>
        </div>

        <div id="mobileContactInfo">
          <a href="mailto:garrett.shannon@gmail.com"><h3 className="contactInfo">garrett.shannon@gmail.com</h3></a>
          <a href="tel:+1 310 880 6155"><h3 className="contactInfo">+1 310 880 6155</h3></a>
          <div id="contactIcons">
            <a id="vimeoLogo" href="https://vimeo.com/garrettshannon" target="_blanc"><VimeoLogo/></a>
            <a id="imdbLogo" href="https://www.imdb.com/name/nm2758759/" target="_blanc"><ImdbLogo/></a>
            <a href="https://www.linkedin.com/in/garrettshannon/" target="_blanc"><LinkedinLogo/></a>
            <a href="https://www.instagram.com/garrettshannon/" target="_blanc"><InstagramLogo/></a>
          </div>
        </div>
      </div>

      <LandingSection/>

      <div id="videosSection">
        <div>
          <h1 className="header1 mobileHeader" id="videoCategories">{category.toUpperCase()}</h1>

          <div className="centerHorizontally" style={{height:'auto'}}>

            <div id="desktopCategories">
                <a href="#videosSection" onClick={()=>{setCategory("Featured"); setMenu("")}}>
                  <h1 className="header1 desktopCategory" id="videoCategories" style={{backgroundPosition: (category === "Featured") ? "0 100%" : ""}}>FEATURED</h1>
                </a>
                <a href="#videosSection" onClick={()=>{setCategory("Commercial"); setMenu("")}}>
                  <h1 className="header1 desktopCategory" id="videoCategories" style={{backgroundPosition: (category === "Commercial") ? "0 100%" : ""}}>COMMERCIAL</h1>
                </a>
                <a href="#videosSection" onClick={()=>{setCategory("Branded Doc"); setMenu("")}}>
                  <h1 className="header1 desktopCategory" id="videoCategories" style={{backgroundPosition: (category === "Branded Doc") ? "0 100%" : ""}}>BRANDED DOC</h1>
                </a>
                <a href="#videosSection" onClick={()=>{setCategory("Music Video"); setMenu("")}}>
                  <h1 className="header1 desktopCategory" id="videoCategories" style={{backgroundPosition: (category === "Music Video") ? "0 100%" : ""}}>MUSIC VIDEO</h1>
                </a>
            </div>
          </div>

          {/* Featured */}
          <div style={{display: (category == "Featured") ? "block": "none"}}>
            <FeaturedVideos modal={modal} setModal={setModal}/>
          </div>

          {/* Branded Doc */}
          <div style={{display: (category == "Branded Doc") ? "block": "none"}}>
            <BrandedDocs modal={modal} setModal={setModal}/>
          </div>

          {/* Commercial */}
          <div style={{display: (category == "Commercial") ? "block": "none"}}>
            <Commercial modal={modal} setModal={setModal}/>
          </div>

          {/* Music Video */}
          <div style={{display: (category == "Music Video") ? "block": "none"}}>
            <MusicVideos modal={modal} setModal={setModal}/>
          </div>

        </div>
      </div>

      <div id="aboutSection">
        <h1 className="header1" id="aboutHeader">ABOUT</h1>
        <div id="aboutWrapper">
          <img src={Headshot} id="aboutHeadshot" />
          <p>Hey y’all. Originally from San Diego I spent over 10 years building my career in Los Angeles. I now call Austin home although I work as a local in both places. I’ve been called relentlessly positive, fiercely loyal, and sorta funny. I’m passionate about collaboration; it’s my absolute favorite thing about what I do and while I take my work seriously I don't take myself too seriously. I own an Arri Amira package with a set of Leica-R PL mount primes that were rehoused by WhitePoint Optics. I belong to the International Cinematographers Guild Local 600 and thanks to my wife’s family, now consider myself a parrot head. I’m extremely creative, driven, hard-working, reliable, and—I’ll admit it—a hoot to work with. I also smoke a mean brisket. My work encompasses a variety of different formats and genres representing a multitude of major brands, cool musicians, and talented filmmakers; I’ve even won a few awards along the way.
          <br/><br/>
          <span style={{fontWeight:'bold'}}>Wanna work together? It’d be a lot cooler if you diiiiiid.</span></p>
        </div>

        <div id="aboutContact">
          <a href="mailto:garrett.shannon@gmail.com"><h3 className="contactInfo aboutContact">garrett.shannon@gmail.com</h3></a>
          <a href="tel:+1 310 880 6155"><h3 className="contactInfo aboutContact">+1 310 880 6155</h3></a>
          <div id="contactIcons" className="aboutContact aboutContactIcons">
            <a id="vimeoLogo" className="contactIcon" href="https://vimeo.com/garrettshannon" target="_blanc"><VimeoLogo/></a>
            <a id="imdbLogo" className="contactIcon" href="https://www.imdb.com/name/nm2758759/" target="_blanc"><ImdbLogo/></a>
            <a  className="contactIcon" href="https://www.linkedin.com/in/garrettshannon/" target="_blanc"><LinkedinLogo/></a>
            <a  className="contactIcon" href="https://www.instagram.com/garrettshannon/" target="_blanc"><InstagramLogo/></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
